import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Shipping = () => {
    const [data, setData] = useState({
        id: '',
        slab200: '',  // Initialize with empty strings or any default values
        slab25: '',
        slab51: '',
        slab11: '',
    });
    const [issubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get("https://logsuntech.com/admin/ship", {
                    withCredentials: true,
                });

                if (res.status === 200) {
                    console.log(res.data);
                    setData(res.data[0]);
                }
            } catch (err) {
                console.log(err);
            }
        };

        getData();
    }, []);

    const hndChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const hndSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        console.log('Form Submitted:', data);

        try {

            const res = await axios.put("https://logsuntech.com/admin/chgShip", { data }, {
                withCredentials: true
            })
            if (res.status === 200) {
                setIsSubmitting(false)
                alert("success")
            }
        } catch (err) {
            setIsSubmitting(false)
            console.log(err)
        }
    };

    return (
        <div>
            <p className='text-center font-font1 text-2xl font-semibold my-4'>
                Shipping Charges Form
            </p>

            <div className='bg-sky-100 py-8'>
                <form onSubmit={hndSubmit} className='space-y-8'>
                    <div className='flex flex-row justify-center gap-8'>
                        <label className='font-font1'>Weight upto 200 gms</label>
                        <input
                            onChange={hndChange}
                            name='slab200'
                            value={data.slab200}
                            placeholder='200 gms'
                            className='rounded pl-2 border border-solid border-gray-400'
                        />
                    </div>

                    <div className='flex flex-row justify-center gap-8'>
                        <label className='font-font1'>Weight 200-500 gms</label>
                        <input
                            onChange={hndChange}
                            name='slab25'
                            value={data.slab25}
                            placeholder='200 gms'
                            className='rounded pl-2 border border-solid border-gray-400'
                        />
                    </div>

                    <div className='flex flex-row justify-center gap-8'>
                        <label>Weight 500-1000 gms</label>
                        <input
                            onChange={hndChange}
                            name='slab51'
                            value={data.slab51}
                            placeholder='500 gms'
                            className='rounded pl-2 border border-solid border-gray-400'
                        />
                    </div>

                    <div className='flex flex-row justify-center gap-8'>
                        <label>Weight above 1000 gms</label>
                        <input
                            onChange={hndChange}
                            name='slab11'
                            value={data.slab11}
                            placeholder='1000 gms'
                            className='rounded pl-2 border border-solid border-gray-400'
                        />
                    </div>

                    <button type='submit' disabled={issubmitting}
                        className='mx-auto block mt-4 bg-sky-500 text-white py-2 px-4 rounded hover:bg-sky-600'>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Shipping;
