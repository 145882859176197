import { createSlice } from "@reduxjs/toolkit";

const footerSlice = createSlice({
    name: 'nav',
    initialState: '',

    reducers: {
        userRole(state, action) {
            return action.payload
        }
    }
});

export const { userRole } = footerSlice.actions;
export default footerSlice.reducer