import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import axios from 'axios';


import { useSelector, useDispatch } from 'react-redux'


const CartTotal = () => {
    const { error, isLoading, Razorpay } = useRazorpay();
    const cartItem = useSelector(state => state.cart)
    const [mode, setMode] = useState("")
    const [orderPrice, setOrderPrice] = useState()
    const [ship, setShip] = useState()

    console.log(cartItem, " total")

    useEffect(() => {
        const getdeliveryCharges = async () => {
            try {
                const res = await axios.get("https://logsuntech.com/user/deliCharges", {
                    withCredentials: true
                });
                if (res.status === 200) {
                    console.log(res.data[0], " ship")
                    setShip(res.data[0])
                }
            } catch (err) {
                console.log(err)
            }
        }

        getdeliveryCharges()
    }, [])

    let cartTotalPrice = 0

    const totalWeight = cartItem.reduce((acc, item) => acc + (Number(item.prodWeight) * Number(item.quantity)), 0)

    cartItem.forEach(item => {
        // Step 1: Calculate discounted price
        const discountedPrice = item.price - (item.price * item.discount / 100);

        // Step 2: Calculate GST on the discounted price
        const gstAmount = discountedPrice * item.gst / 100;

        // Step 3: Calculate final price for the item
        const finalPrice = (discountedPrice + gstAmount) * item.quantity;

        // Add the final price for this item to the total
        cartTotalPrice += finalPrice;
    });

    console.log(cartTotalPrice, " cartTotal")

    const handlePayment = async () => {
        try {
            // Step 1: Call your API to create an order
            const res = await axios.post("https://logsuntech.com/user/order", { cartItem, orderPrice }, {
                withCredentials: true
            });

            const orderDetails = res.data;
            if (!orderDetails.success) {
                return alert('Payment order creation failed');
            }

            // Step 2: Setup Razorpay payment options
            const options = {
                key: "rzp_test_Wz6Ic4g5kS5R9i", // your Razorpay public key
                amount: orderDetails.amount,  // amount in paise
                currency: orderDetails.currency,
                order_id: orderDetails.orderId,  // order ID generated by the backend
                name: "Logsun",  // Your shop's name
                description: "cart Payment",  // Description of the order
                handler: async function (response) {
                    // This function will be called on successful payment
                    console.log(response);
                    //alert("Payment successful");
                    const validateResponse = await fetch("https://logsuntech.com/user/validate", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(response),
                        credentials: 'include'  // This is equivalent to withCredentials: true in XHR
                    });

                    const jsonResponse = await validateResponse.json();
                    console.log(jsonResponse, " json")

                    if (jsonResponse.msg2 === "paid") {
                        alert("order placed")
                    }
                },
                prefill: {
                    name: "Logsun",  // Default user details
                    email: "logsun@gmail.com",
                    contact: "9422016296",
                },
                notes: {
                    address: "Some address",  // You can add additional notes if needed
                },
                theme: {
                    color: "#F37254",  // Optional theme color
                },
            };

            // Step 3: Initialize Razorpay payment gateway
            const rzp1 = new Razorpay(options);

            // Step 4: Open the Razorpay payment modal
            rzp1.open();
        } catch (err) {
            console.log(err);
            alert("An error occurred while creating the payment order.");
        }
    };

    const modeHnd = (e) => {
        const { name } = e.target;
        if (name === 'pick') {
            setMode('pick')
            setOrderPrice(cartTotalPrice)
        } else {
            setMode('deliver')
            if (totalWeight < 200) {
                setOrderPrice(cartTotalPrice + ship.slab200)
            } else if (totalWeight > 201 && Number(totalWeight) < 499) {
                setOrderPrice(cartTotalPrice + ship.slab25)
            } else if (totalWeight > 500 && Number(totalWeight) < 999) {
                setOrderPrice(cartTotalPrice + ship.slab51)
                console.log(cartTotalPrice + ship.slab51)
            } else if (totalWeight > 1000) {
                setOrderPrice(cartTotalPrice + ship.slab11)
            }
        }
    }




    return (
        <div className='mt-4'>
            <p className='text-center font-font1 text-2xl font-semibold'>Cart Details:</p>
            <p className='text-center font-font1'>Total Weight: {totalWeight} grams</p>

            <div className='flex flex-row justify-center gap-8 my-4'>
                <button onClick={modeHnd} name='pick' className={`${mode === 'pick' ? 'bg-sky-400' : 'bg-sky-200'}  py-2 px-4 rounded`}>Pick up</button>
                <button onClick={modeHnd} name='deliver' className={`${mode === 'deliver' ? 'bg-sky-400' : 'bg-sky-200'} py-2 px-4 rounded`}>Delivery</button>
            </div>
            <p className='text-center font-font1 text-xl font-medium'>Cart Total: {cartTotalPrice}</p>

            <button onClick={handlePayment}
                className='bg-sky-500 mt-4 px-4 py-2 mx-auto block'>Buy Now</button>
        </div>
    )
}

export default CartTotal