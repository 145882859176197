import { createSlice } from "@reduxjs/toolkit";

const navSlice = createSlice({
    name: 'nav',
    initialState: 'Guest',

    reducers: {
        userName(state, action) {
            return action.payload
        }
    }
});

export const { userName } = navSlice.actions;
export default navSlice.reducer