import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

import axios from 'axios'

const CartUserDetails = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get("https://logsuntech.com/user/cartUser", {
                    withCredentials: true
                });
                if (res.status === 200) {
                    console.log(res.data)
                    setData(res.data)
                }
            } catch (err) {
                console.log(err)
            }

        }

        getData()
    }, [])

    const editHnd = (mobileNo) => {
        navigate(`/userEdit/${mobileNo}`)
    }

    if (!data.length > 0) {
        return <Skeleton active />
    }

    return (
        <div className=''>
            <p className='font-font1 text-center text-xl font-medium'>User Details:</p>

            {data.map((item, index) => {
                return <div key={index} className='ml-4'>
                    <p className='font-font1 text-center'>Name: {item.name}</p>
                    <p className='font-font1 text-center'>Mobile: {item.mobile_no}</p>
                    <p className='font-font1 text-center'>Email: {item.mail}</p>
                    <p className='font-font1 text-center'>Address: {item.address}</p>
                    <p className='font-font1 text-center'>Pincode: {item.pincode}</p>

                    <button onClick={() => { editHnd(item.mobile_no) }} className='bg-gray-300  my-2 px-4 rounded-md py-2 mx-auto block hover:bg-gray-400'>Edit</button>
                </div>
            })}

        </div>
    )
}

export default CartUserDetails;