import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import FeaturedList from './FeaturedList';



const Categories = () => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeSubCategory1, setActiveSubCategory1] = useState(null);

    const [myData, setMyData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get("https://logsuntech.com/user/getCategory");
                setMyData(res.data);
                console.log(res.data, " cat")


            } catch (err) {
                console.log(err);
            }

        }

        getData()
    }, [])

    // Helper to split the subCategory2List into an array
    const parseSubCategory2List = (subCategory2List) => {
        return subCategory2List.split(',').filter(item => item !== '-');
    };

    // Remove duplicate categories
    const uniqueCategories = Array.from(new Set(myData.map(item => item.category)))
        .map(category => myData.find(item => item.category === category));

    // Handlers for hover events
    const handleCategoryHover = (category) => {
        setActiveCategory(category);
        setActiveSubCategory1(null); // Reset sub-category on category hover
    };

    const handleSubCategory1Hover = (subCategory1) => {
        setActiveSubCategory1(subCategory1);
    };

    const handleCategoryLeave = () => {
        setActiveCategory(null);
        setActiveSubCategory1(null);
    };

    const subCatHnd = (itemName) => {
        localStorage.setItem("subCat", itemName)
        navigate('/categoryProduct')
    }

    const subCatHnd2 = (itemName) => {
        localStorage.setItem("subCat", itemName)
        navigate('/subCategoryProduct')
    }


    return (
        <div className="flex flex-col p-4 space-y-4">
            <div className="space-y-2">
                {uniqueCategories.map((categoryData, index) => (
                    <div
                        key={index}
                        className="group relative"
                        onMouseEnter={() => handleCategoryHover(categoryData.category)}
                        onMouseLeave={handleCategoryLeave}
                    >
                        {/* Category Button */}
                        <button
                            className="w-full text-left px-4 py-2 text-lg font-medium bg-sky-200 rounded-lg hover:bg-sky-300"
                        >
                            {categoryData.category}
                        </button>

                        {/* SubCategory1 - Only show when the category is hovered */}
                        {activeCategory === categoryData.category && (
                            <div className="pl-6 mt-2 space-y-1">
                                {categoryData.subCategory1 !== '-' && (
                                    <div
                                        className="group relative"
                                        onMouseEnter={() => handleSubCategory1Hover(categoryData.subCategory1)}
                                    >
                                        <button onClick={() => { subCatHnd(categoryData.subCategory1) }} className="text-left px-4 py-2 text-sm bg-sky-100 rounded-md hover:bg-sky-200">
                                            {categoryData.subCategory1}
                                        </button>

                                        {/* SubCategory2 - Only show when the subCategory1 is hovered */}
                                        {activeSubCategory1 === categoryData.subCategory1 && (
                                            <div className="pl-6 mt-2 space-y-1">
                                                {parseSubCategory2List(categoryData.subCategory2List).map((subCategory2, idx) => (
                                                    <div key={idx}>
                                                        <button onClick={() => { subCatHnd2(subCategory2) }} className="text-left px-4 py-2 text-md text-sky-700 bg-sky-50 rounded-md hover:bg-sky-100">
                                                            {subCategory2}
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
