import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Cookies from 'js-cookie';

import CartUserDetails from './CartUserDetails';
import CartHistory from './CartHistory';

import { useSelector, useDispatch } from 'react-redux';
import { addQuantity, minusQuantity, addToCart, removeFromCart } from '../../redux/slices/cartSlice'

import CartTotal from './CartTotal';

const Cart = () => {
    const dispatch = useDispatch();
    const cartItem = useSelector(state => state.cart)
    const [hist, setHist] = useState(false)
    const totalPrice = cartItem?.map(item => Number(item.price) * Number(item.quantity))?.reduce((acc, item) => acc + item, 0);

    const navigate = useNavigate();

    useEffect(() => {
        const tkn = Cookies.get('token2')

        if (tkn) {
            const getData = async () => {
                try {
                    const res = await axios.get("https://logsuntech.com/user/addedCartProducts",
                        { withCredentials: true });

                    if (res.status === 200) {
                        console.log(res.data)
                        dispatch(addToCart(res.data))
                    }
                } catch (err) {
                    console.log(err)
                }
            }


            getData()
        }

    }, [])


    const minusHnd = async (item) => {
        dispatch(minusQuantity(item.productId))
        const qty = Number(item.quantity) - 1
        if (qty > 0) {
            try {
                const res = await axios.put(`https://logsuntech.com/user/plusQtyCart`,
                    { qty, itemId: item.productId, name: "minus" },
                    { withCredentials: true }
                )
            } catch (err) {
                alert("Network issue")
                console.log(err)
            }

        }


    }

    const plusHnd = async (item) => {
        console.log(item)
        dispatch(addQuantity((item.productId)));
        const qty = Number(item.quantity) + 1


        try {
            const res = await axios.put(`https://logsuntech.com/user/plusQtyCart`,
                { qty, itemId: item.productId, name: "plus" },
                { withCredentials: true }
            )
        } catch (err) {
            alert("Network issue")
        }
    }

    const deleteHnd = async (productId) => {
        dispatch(removeFromCart(productId));
        try {
            const res = await axios.delete(`https://logsuntech.com/user/cartRemove/${productId}`, {
                withCredentials: true
            })
        } catch (err) {
            console.log(err)
        }
    }



    return (
        <div className='mt-24 mb-20'>
            <p className='mb-4 font-font1 text-center text-xl font-medium'>My Cart</p>

            <div className='flex flex-row justify-center gap-8 my-4 bg-sky-200 py-2 w-4/12 mx-auto rounded-md'>
                <button onClick={() => { setHist(false) }} className={`${hist ? "bg-sky-100" : "bg-sky-500"} font-font1 px-4 py-2 rounded-md`}>Cart Items</button>
                <button onClick={() => { setHist(true) }} className={`${hist ? "bg-sky-500" : "bg-sky-100"} font-font1 px-4 py-2 rounded-md`}>Orders History</button>
            </div>

            {!hist && <div className='flex flex-row gap-4 bg-gray-100'>
                <div className='w-7/12'>
                    {cartItem.map((item, index) => {
                        return <div key={index} className='bg-white flex flex-row gap-4  border border-solid border-gray-300 rounded-md mb-4'>
                            <div className='w-3/12 h-32 my-2'>
                                <div className='h-full w-full transform transition-transform duration-300 hover:scale-110 bg-cover bg-center' style={{ backgroundImage: `url(https://logsuntech.com/banner/${item.prodImages})` }}></div>
                            </div>
                            <div>
                                <p className='font-font1 text-xl '>{item.name}</p>
                                <p className='font-font1 text-xl font-medium'>Rs. {item.price}</p>

                                <div>
                                    <div className='flex flex-row gap-2'>
                                        <button onClick={() => { minusHnd(item) }} className='font-medium w-8 h-8 rounded-full border border-solid border-gray-400'>-</button>
                                        <input min={1} readOnly value={item.quantity} className='border border-solid border-gray-400 w-12 text-center'></input>
                                        <button onClick={() => { plusHnd(item) }} className='font-medium w-8 h-8 rounded-full border border-solid border-gray-400'>+</button>
                                    </div>
                                    <button onClick={() => { deleteHnd(item.productId) }} className='bg-sky-400 mt-4 px-4 py-2 mb-2 rounded-md hover:bg-sky-600'>Delete</button>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                {/* cards div */}

                {/* total div */}

                <div className='w-4/12 bg-white rounded-md font-font1 border border-solid border-gray-300 shadow'>
                    {/* <p className='text-xl text-gray-600 font-medium m-4'>Price Details</p> */}
                    {/* <p className='ml-4 text-xl font-medium'>Total Amount: Rs.{totalPrice}</p> */}

                    <div>
                        <CartTotal></CartTotal>
                    </div>

                    <div className='mt-4'>
                        <CartUserDetails></CartUserDetails>
                    </div>
                </div>
            </div>}

            {hist && <div>
                <CartHistory></CartHistory>
            </div>}
        </div>
    )
}

export default Cart;