import React from 'react'

const SubCategoryProduct = () => {
    return (
        <div>
            <p>SubCategoryProduct</p>
        </div>
    )
}

export default SubCategoryProduct