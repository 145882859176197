import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SearchBar = ({ clkHnd }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const navigate = useNavigate()

    // Debounce the input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    // Fetch results when debouncedQuery changes
    useEffect(() => {
        if (debouncedQuery) {
            const fetchResults = async () => {
                const response = await axios.get(`https://logsuntech.com/user/search?query=${debouncedQuery}`);
                setResults(response.data);
            };
            fetchResults();
        } else {
            setResults([]);
        }
    }, [debouncedQuery]);

    const resultHnd = async (id) => {
        navigate(`/details/${id}`)
    }

    return (
        <div>
            <input
                type="text"
                placeholder="Search by Product name, category..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className='w-10/12 mx-auto block rounded-lg h-12 my-4 pl-8 border-2 border-solid border-blue-800'
            />
            {query.length > 0 && <ul className=' z-10 border border-solid border-blue-700 shadow-lg w-10/12 mx-auto block'>
                {results.map(item => (
                    <li key={item.id} onClick={() => { clkHnd(item.id) }}
                        className='bg-sky-100 mb-4 w-full px-4 py-2 mx-auto block hover:bg-sky-600'>{item.name} </li>
                ))}
            </ul>}
        </div>
    );
};

export default SearchBar;
