import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Sequence = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    const navigate = useNavigate();

    // Debounce the input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    // Fetch results when debouncedQuery changes
    useEffect(() => {
        if (debouncedQuery) {
            const fetchResults = async () => {
                try {
                    const response = await axios.get(`https://logsuntech.com/admin/searchSequence?query=${debouncedQuery}`, {
                        withCredentials: true
                    });
                    console.log(response.data)
                    setResults(response.data);
                } catch (err) {
                    console.log(err)
                }

            };
            fetchResults();
        } else {
            setResults([]);
        }
    }, [debouncedQuery]);

    const recordHnd = (id) => {
        //console.log(id)
        navigate(`/seqEditPage/${id}`)
    }

    return (
        <div>
            <p className='font-font1 text-xl font-semibold text-center my-4'>Products Sequence</p>
            <input
                type="text"
                placeholder="Search by name, category..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className='w-10/12 mx-auto block rounded-lg h-12 my-4 pl-8 border-2 border-solid border-blue-800'
            />
            {query.length > 0 && <ul className=' z-10 border border-solid border-blue-700 shadow-lg w-10/12 mx-auto block'>
                {results.map(student => (
                    <li onClick={() => { recordHnd(student.id) }} key={student.id}
                        className='bg-gray-200 mb-4 w-10/12 py-2 mx-auto block hover:bg-sky-300'>{student.name}  {student.email}</li>
                ))}
            </ul>}
        </div>
    );
};

export default Sequence;
