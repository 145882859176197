import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios';
import Banner from '../Home/Banner';
import Categories from '../Home/Categories';
import FeaturedList from '../Home/FeaturedList';
import Num from '../Home/Num';
import Values from '../Home/Values';
import SearchBar from '../Nav/SearchBar';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/slices/cartSlice';



const HomePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const tkn = Cookies.get('token2')

        if (tkn) {
            const getData = async () => {
                try {
                    const res = await axios.get("https://logsuntech.com/user/addedCartProducts",
                        { withCredentials: true });

                    if (res.status === 200) {
                        //console.log(res.data)
                        dispatch(addToCart(res.data))
                    }
                } catch (err) {
                    console.log(err)
                }
            }


            getData()
        }

    }, [])

    const clkHnd = (id) => {
        navigate(`/details/${id}`)
    }

    return (
        <div className='mt-16 mb-24'>
            <div className='bg-[rgba(135,206,235,0.5)] mt-16 ' style={{ padding: '1px' }}>
                <SearchBar clkHnd={clkHnd}></SearchBar>
            </div>
            <div className='flex flex-row justify-center mx-4 '>

                {/* 30% */}
                <div className="w-1/4 border-2 flex justify-center">
                    <Categories></Categories>
                </div>


                {/* 70% */}
                <div className="w-3/4">
                    <Banner></Banner>
                    <hr className='border-1 border-gray-400'></hr>
                    <FeaturedList></FeaturedList>
                </div>


            </div>
            <Num></Num>
            <Values></Values>
        </div>
    )
}

export default HomePage;