import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';

import ProductTabs from './ProductTabs';

const ProductDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`https://logsuntech.com/user/prodDetails/${id}`);
                //console.log(res.data);

                const faqs = JSON.parse(res.data[0].faq)
                res.data[0].faq = faqs
                setData(res.data);

            } catch (err) {
                console.log(err);
            }
        };

        getData();
    }, [id]);

    const qtyHnd = (e) => {
        setQuantity(e.target.value);
    };

    return (
        <div className='mt-24'>
            {data?.map((item, index) => (
                <div key={index}>
                    <div className='flex flex-row justify-between mx-12'>
                        <div className='border border-solid border-gray-300 rounded-md w-6/12'>
                            <div
                                className='h-[52vh] bg-cover bg-center'
                                style={{
                                    backgroundImage: `url(https://logsuntech.com/banner/${item.prodImages})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            ></div>
                            <div className='flex flex-row justify-center gap-4 mt-8'>
                                {item.prodImages.split(",").map((pic, index) => (
                                    <div
                                        key={index}
                                        className='h-[12vh] w-12 border border-solid border-gray-700 bg-cover bg-center'
                                        style={{ backgroundImage: `url(https://logsuntech.com/banner/${pic})` }}
                                    ></div>
                                ))}
                            </div>
                        </div>
                        <div className='border border-solid border-gray-300 w-3/12'>
                            <p className='font-font1 text-center text-4xl font-semibold mt-4'>{item.name}</p>
                            <p className='font-font1 text-center text-lg mt-4'>Brand: {item.manufacturerName}</p>
                            <p className='font-font1 text-center text-lg'>Availability: In Stock</p>
                            <p className='font-font1 text-center text-2xl font-semibold mt-4'>Rs. {item.price}.00</p>
                            <p className='font-font1 text-center text-lg'>GST: {item.gst}%</p>
                            <div className='flex flex-row justify-center gap-4 font-font1 mt-4'>
                                <label>Quantity:</label>
                                <input
                                    onChange={qtyHnd}
                                    type='number'
                                    min={0}
                                    className='border font-font1 text-xl text-center rounded border-solid w-16 border-gray-400'
                                />
                            </div>
                            <button className='bg-blue-300 mt-4 font-font1 mx-auto block py-2 px-4 rounded-md hover:bg-blue-400'>Add To Cart</button>
                        </div>
                    </div>

                    {/* Description */}
                    {/* <div className='mt-8 mx-12'>
                        <p className='text-center font-font1 text-xl'>Description</p>
                        <div
                            className='description' // Optional: add your styling here
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }}
                        />
                    </div> */}
                </div>
            ))}
            {(data && data.length > 0) && <ProductTabs data={data}></ProductTabs>}
        </div>
    );
};

export default ProductDetails;
