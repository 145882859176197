import React from 'react';
import { Tabs, Collapse } from 'antd';
import DOMPurify from 'dompurify';

const onChange = (key) => {
    console.log(key);
};

const ProductTabs = ({ data }) => {
    console.log(data, " data")
    const collapseData = data[0]?.faq?.map((item, index) => ({
        key: index + 1,  // Ensure keys are unique
        label: item.question,  // Set the question as the label
        children: <p>{item.answer}</p>,  // Set the answer as the content
    }));
    return (
        <Tabs className='mt-8 mx-8 mb-8'
            onChange={onChange}
            type="card"
            items={[
                {
                    label: <span className='font-font1 text-xl'>Description</span>,
                    key: '1',
                    children: (
                        <div>
                            {data?.map((item, index) => (
                                <div key={index} className='ml-8'> {/* Add key here */}
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }} />
                                </div>
                            ))}
                        </div>
                    ),
                },
                {
                    label: <span className='font-font1 text-xl'>FAQs</span>,
                    key: '2',
                    children: (
                        <Collapse
                            className='bg-sky-100 border-border-solid border-black'
                            accordion size='large' items={collapseData} defaultActiveKey={['1']} ></Collapse>
                    ),
                },
                {
                    label: <span className='font-font1 text-xl'>Links</span>,
                    key: '3',
                    children: <div>
                        {data[0]?.docLinks.split(',').map((item, index) => {
                            return <div key={index} className='mb-4'>
                                {/* <p className='font-font1 text-lg'>{index + 1}. {item}</p> */}
                                <a href={item} target="_blank" rel="noopener noreferrer"
                                    className='font-font1 text-md mb-2'>
                                    {item}
                                </a>
                            </div>
                        })}
                    </div>,
                },
            ]}
        />
    );
};

export default ProductTabs;
