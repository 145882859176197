// Navbar.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


import { useSelector, useDispatch } from 'react-redux';
import { navSlice } from '../../redux/slices/navSlice'

import cart from '../asset/cart.png'
import logo from '../asset/logsun.png'

const Navi = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const [userName, setUserName] = useState(null)
    const counter = useSelector(state => state.cart)
    const navName = useSelector(state => state.navName)

    const navigate = useNavigate()

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const logHandle = () => {
        if (!userName) {
            navigate('/login')
        } else {
            Cookies.remove('token2')
            window.location.reload()
        }
    }

    useEffect(() => {
        //setUserName(localStorage.getItem('name'))
        const user = Cookies.get('token2')
        if (user) {
            setUserName(user)
        }
    }, [])

    return (
        <div>
            <nav className="bg-sky-200 fixed w-full top-0 left-0 z-50 shadow-md">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <div className="flex items-center text-white">
                            <Link to='/'><img src={logo} className='w-28 mt-2'></img></Link>

                        </div>

                        <div className="hidden md:flex md:space-x-8">

                            <NavLink
                                to="/"
                                className={({ isActive }) => `text-black hover:text-sky-800 ${isActive ? 'text-black font-bold' : ''} flex items-center`}
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to="/about"
                                className={({ isActive }) => `text-black hover:text-sky-800 ${isActive ? 'text-black font-bold' : ''} flex items-center`}
                            >
                                About
                            </NavLink>

                            <NavLink
                                to="/contact"
                                className={({ isActive }) => `text-black hover:text-sky-800 ${isActive ? 'text-black font-bold' : ''} flex items-center`}
                            >
                                Contact
                            </NavLink>

                            {<p className='text-black font-font1 text-lg font-semibold'>{navName.toUpperCase()}</p>}

                            <button onClick={logHandle} className='bg-sky-800 text-white px-4 py-1 font-font1 rounded-lg hover:bg-sky-600'>{userName ? 'Logout' : 'Login'}</button>

                            <div className='flex flex-row'>
                                <Link to='/cart'><img src={cart} className='w-8'></img></Link>
                                <p className='text-black ml-2 font-medium'>{counter.length}</p>
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                onClick={toggleMenu}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <NavLink
                            to="/"
                            className={({ isActive }) => `text-gray-300 block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 ${isActive ? 'bg-gray-700 text-white font-bold' : ''} flex items-center`}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/about"
                            className={({ isActive }) => `text-gray-300 block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 ${isActive ? 'bg-gray-700 text-white font-bold' : ''} flex items-center`}
                        >
                            About
                        </NavLink>
                        <NavLink
                            to="/services"
                            className={({ isActive }) => `text-gray-300 block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 ${isActive ? 'bg-gray-700 text-white font-bold' : ''} flex items-center`}
                        >
                            Services
                        </NavLink>
                        <NavLink
                            to="/contact"
                            className={({ isActive }) => `text-gray-300 block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 ${isActive ? 'bg-gray-700 text-white font-bold' : ''} flex items-center`}
                        >
                            Contact
                        </NavLink>
                    </div>
                </div>
            </nav>


        </div>
    );
};

export default Navi;
