import { configureStore } from "@reduxjs/toolkit";
import cartSlice from './slices/cartSlice';
import featuredSlice from './slices/featuredSlice'
import navSlice from './slices/navSlice'
import footerSlice from './slices/footerSlice'

const store = configureStore({
    reducer: {
        cart: cartSlice,
        featuredProducts: featuredSlice,
        navName: navSlice,
        footerUser: footerSlice,
    }
});


export default store;