import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import SearchBar from '../../components/Nav/SearchBar'

import bin from '../../components/asset/bin.png'

import { Button, message, Popconfirm } from 'antd'

const confirm = async (id) => {

    message.success('Click on Yes');

    try {
        const res = await axios.delete(`https://logsuntech.com/admin/deleteProduct/${id}`, {
            withCredentials: true
        });
        if (res.status === 200) {
            message.success("Product deleted successfully")
        }
    } catch (err) {
        alert("server issue")
    }
};
const cancel = (e) => {
    console.log(e);
    message.error('Click on No');
};

const ProductsList = () => {
    const [data, setData] = useState()
    const [dummy, setDummy] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get('https://logsuntech.com/admin/getProducts', {
                    withCredentials: true
                })
                console.log(res.data)
                setData(res.data)
            } catch (err) {
                console.log(err)
            }

        }

        getData()
    }, [dummy])

    const featureHnd = async (id, num) => {

        try {
            const res = await axios.post("https://logsuntech.com/admin/chgFeatured", { id, num })
            if (res.status == 200) {
                setDummy(!dummy)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const activateHnd = async (id, num) => {
        try {
            const res = await axios.post("https://logsuntech.com/admin/chgActive", { id, num })
        } catch (err) {
            console.log(err)
        }
    }

    const clkHnd = (id) => {
        const filteredData = data.filter(item => item.id == id)
        setData(filteredData)
    }

    return (
        <div className='mt-4'>
            <div className='bg-[rgba(135,206,235,0.5)] mt-4 rounded-md ' style={{ padding: '1px' }}>
                <SearchBar clkHnd={clkHnd}></SearchBar>
            </div>
            <p className='text-center font-font1 text-xl font-semibold mb-2'>Products List</p>

            <div className='flex flex-row justify-center gap-4 flex-wrap'>
                {data?.map((product, index) => {
                    return <div key={index} className='border border-solid border-gray-400 py-4 px-4 rounded-xl'>
                        {/* ant design component to confirm delete */}

                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => { confirm(product.id) }}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button className='mx-auto block font-font1 mb-2 rounded px-2  hover:bg-rose-200'>
                                <img src={bin} className='w-6'></img>
                            </button>
                        </Popconfirm>

                        <p className='font-font1 font-medium text-center'>Name: {product.name}</p>
                        <p className='font-font1 font-medium text-center'>Price: {product.price}</p>
                        <p className='font-font1 font-medium text-center'>Quantity: {product.quantity}</p>

                        <div className='flex flex-row justify-center gap-2'>
                            <Link to={`/productEdit/${product.id}`}><button className='bg-sky-300 font-font1 py-2 rounded px-4 hover:bg-sky-500'>Edit</button></Link>
                            <button onClick={() => { activateHnd(product.id, product.active) }} className={`${product.active == '0' ? 'bg-sky-300' : 'bg-sky-500'} font-font1 py-2 rounded px-4 hover:bg-sky-500`}>{product.active == '0' ? 'Enable' : 'Disable'}</button>
                            <button onClick={() => { featureHnd(product.id, product.featured) }} className={`${product.featured === '1' ? "bg-sky-500" : "bg-sky-300"} font-font1 py-2 rounded px-4 hover:bg-sky-500`}>{product.featured === '1' ? 'unFeature' : "Featured"}</button>

                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default ProductsList