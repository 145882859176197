import React from 'react'
import About1 from '../about/About1';


const AboutPage = () => {
    return (
        <div>
            <About1></About1>


        </div>
    )
}

export default AboutPage;