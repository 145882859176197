import React, { useEffect, useState } from 'react'
import axios from 'axios'

const CartHistory = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get("https://logsuntech.com/user/cartHist", {
                withCredentials: true
            });

            if (res.status === 200) {
                console.log(res.data)
                setData(res.data)
            }
        }



        getData()
    }, [])

    return (
        <div className='w-8/12 '>
            {data.map((item, index) => {
                return <div key={index} className='bg-white flex flex-row gap-4  border border-solid border-gray-300 rounded-md mb-4'>
                    <div className='w-1/12 h-20 my-2 ml-4'>
                        <div className='h-full w-full transform transition-transform duration-300 hover:scale-110 bg-cover bg-center' style={{ backgroundImage: `url(https://logsuntech.com/banner/${item.prodImages})` }}></div>
                    </div>
                    <div>
                        <p className='font-font1 text-xl '>{item.name}</p>
                        <p className='font-font1 text-xl font-medium'>Rs. {item.price}</p>
                        <p className='font-font1 text-gray-400'>Purchased At: {item.validateTime.split('T')[0]}</p>


                    </div>
                </div>
            })}
        </div>
    )
}

export default CartHistory