import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const DetailsEdit = () => {
    const { mobileNo } = useParams()
    const [data, setData] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`https://logsuntech.com/user/editUser/${mobileNo}`, {
                    withCredentials: true
                })

                if (res.status === 200) {
                    console.log(res.data)
                    setData(res.data[0])
                }
            } catch (err) {
                console.log(err)
            }

        }

        getData();
    }, [])

    const hndChg = (e) => {
        const { name, value } = e.target

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const hndSubmit = async (e) => {
        e.preventDefault();
        //console.log(data)

        try {
            setIsSubmitting(true)
            const res = await axios.put("https://logsuntech.com/user/editPut", { data }, {
                withCredentials: true
            })
            if (res.status === 200) {
                setIsSubmitting(false)
                alert("Details Updated")
            }
        } catch (err) {
            setIsSubmitting(false)
            alert("Network error")
            console.log(err)
        }
    }

    return (
        <div className='mt-20'>
            <p className='text-center font-font1 text-2xl font-semibold mb-8'>User Details Edit</p>

            <div className='w-7/12 flex justify-center bg-sky-100 mx-auto py-8 mb-16 rounded-md border border-solid border-sky-600'>
                <form onSubmit={hndSubmit} className='flex flex-col space-y-4 w-full'>
                    <input name='name' onChange={hndChg} required value={data?.name} maxLength={51} type='text' placeholder='Name'
                        className='pl-4 mx-auto w-10/12 border border-solid border-gray-500 h-12 rounded-md'></input>
                    <input name='mail' onChange={hndChg} required value={data?.mail} maxLength={51} type='text' placeholder='Email'
                        className='pl-4 mx-auto w-10/12 border border-solid border-gray-500 h-12 rounded-md'  ></input>
                    <input name='mobile_no' onChange={hndChg} required value={data?.mobile_no} maxLength={10} type='text' placeholder='Mobile'
                        className='pl-4 mx-auto w-10/12 border border-solid border-gray-500 h-12 rounded-md' ></input>
                    <input name='address' onChange={hndChg} required value={data?.address} maxLength={151} type='text' placeholder='Address'
                        className='pl-4 mx-auto w-10/12 border border-solid border-gray-500 h-12 rounded-md' ></input>
                    <input name='pincode' onChange={hndChg} required value={data?.pincode} maxLength={10} type='text' placeholder='Pincode'
                        className='pl-4 mx-auto w-10/12 border border-solid border-gray-500 h-12 rounded-md' ></input>

                    <button disabled={isSubmitting} className={`w-10/12 mx-auto block ${isSubmitting ? 'bg-gray-400' : 'bg-sky-500'}  h-12 rounded-md hover:bg-sky-600`} type='submit'>Submit</button>
                </form>
            </div>

        </div>
    )
}

export default DetailsEdit;