import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const SequenceEdit = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [roll, setRoll] = useState({
        roll1: '',
        roll2: '',
        roll3: '',
    })

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`https://logsuntech.com/admin/seqEdit/${id}`, {
                    withCredentials: true
                });

                if (res.status === 200) {
                    console.log(res.data)
                    setData(res.data)

                    // Assuming res.data is an array, and setting the first item's roll numbers
                    if (res.data.length > 0) {
                        const item = res.data[0];
                        setRoll({
                            roll1: item.rollNo1,
                            roll2: item.rollNo2,
                            roll3: item.rollNo3,
                        });
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }

        getData()
    }, [id]);

    const hndChange = (e) => {
        const { name, value } = e.target
        // Update roll state when user changes input field
        setRoll(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const hndSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await axios.put("https://logsuntech.com/admin/seqEditPost", { roll, id }, {
                withCredentials: true
            });

            if (res.status === 200) {
                alert("updated successfully")

            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='my-16 '>
            <p className='mb-8 mt-4 font-font1 text-center text-xl font-semibold'>Sequence Edit</p>

            <div>
                {data?.map((item, index) => {
                    return <div key={index}>
                        <form onSubmit={hndSubmit}
                            className='flex flex-row justify-center gap-4'>

                            <label>{item.category}:</label>
                            <input
                                onChange={hndChange}
                                name='roll1'
                                value={roll.roll1} // Bind to roll state 
                                type='text'
                                className='pl-2 border border-solid border-gray-400'
                            />
                            <label>{item.subCategory1}:</label>
                            <input
                                onChange={hndChange}
                                name='roll2'
                                value={roll.roll2}
                                type='text'
                                className='pl-2 border border-solid border-gray-400'
                            />
                            <label>{item.subCategory2}:</label>
                            <input
                                onChange={hndChange}
                                name='roll3'
                                value={roll.roll3}
                                type='text'
                                className='pl-2 border border-solid border-gray-400'
                            />

                            <button type='submit'
                                className='bg-sky-300 py-2 px-4 rounded'>Submit</button>
                        </form>
                    </div>
                })}
            </div>
        </div>
    )
}

export default SequenceEdit
